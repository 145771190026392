import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "../components/SectionTitle";
import { RoomsCarousel } from './RoomsCarousel';

export default function AboutUs(props) {

    const useStyles = makeStyles((_) => ({
        carousel: {
            margin: "20px 0",
        }
      }));
      const classes = useStyles();

    return (
        <>
            <SectionTitle title="Habitaciones"/>
            <RoomsCarousel />
        </>
    );
}